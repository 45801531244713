<template>
  <section class="materials-edit-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="styleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching style data
      </h4>
      <div class="alert-body">
        No style found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-styles-list'}"
        >
          Styles
        </b-link>
        list.
      </div>
    </b-alert>

    <b-row v-if="styleData">

      <b-col
        cols="12"
      >
        <b-card>
          <div class="d-flex mt-1 mb-1 justify-content-start">
            <div class="mr-5">
              <b-media
                left-align
                vertical-align="center"
              >
                <template #aside>
                  <b-img
                    v-if="styleData.cover_image"
                    rounded
                    height="64"
                    :src="styleData.cover_image"
                  />
                </template>
                <h4 class="card-title mt-0 mb-1">
                  {{ styleData.name }}
                </h4>
                <h6 class="card-subtitle text-muted">
                  {{ styleData.ref }}
                </h6>
              </b-media>
            </div>
            <div class="mr-5">
              <h5 class="mt-0 mb-1 text-muted">
                Category
              </h5>
              <h6 class="card-subtitle ">
                {{ categoryText(styleData.category) }}
              </h6>
            </div>
            <div class="mr-5">
              <h5 class="mt-0 mb-1 text-muted">
                Brand
              </h5>
              <h6 class="card-subtitle ">
                {{ styleData.brand_name }}
              </h6>
            </div>
            <div>
              <h5 class="mt-0 mb-1 text-muted">
                Status
              </h5>
              <h6 class="card-subtitle ">
                {{ styleData.status }}
              </h6>
            </div>
          </div>
        </b-card>

        <div class="d-flex align-items-center mb-2">
          <b-button
            variant="outline-primary"
            size="md"
            class="mr-2"
            @click="goBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="16"
              class="mr-2"
            />Back
          </b-button>
          <h4 class="mb-0">
            {{ headerTitle }}
          </h4>
        </div>

        <div
          class="accordion"
          role="tablist"
        >
          <b-card
            v-if="availableSections.includes('specifics')"
            no-body
            class="mb-1 card-overflow-visible"
          >
            <b-card-header
              header-tag="header"
              class="p-1 "
              role="tab"
            >
              <b-button
                block
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'specifics'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('specifics') }}
                </b-badge>
                Specifics characteristics
              </b-button>
            </b-card-header>
            <b-collapse
              id="specifics"
              :visible="activeSection === 'specifics'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <b-form-group
                    label="Quality *"
                    label-for="quality"
                  >
                    <b-form-select
                      v-model="quality"
                      required
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="qualityOptions"
                      input-id="quality"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="categoryType === 'embroidery'"
                    label="Threads Quality"
                    label-for="threads-quality"
                  >
                    <b-form-select
                      v-model="threadsQuality"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="threadsQualityOptions"
                      input-id="threads-quality"
                    />
                  </b-form-group>
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      @click="onSubmit"
                    >
                      Next
                    </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('dimensions')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="dimensionsDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'dimensions'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('dimensions') }}
                </b-badge>
                Dimensions
              </b-button>
            </b-card-header>
            <b-collapse
              id="dimensions"
              :visible="activeSection === 'dimensions'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <b-form-group
                    label="Size"
                    label-for="size"
                  >
                    <b-input-group>
                      <b-form-input
                        id="size"
                        v-model="size"
                        trim
                      />
                      <b-input-group-append>
                        <b-form-select
                          v-model="sizeUnits"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="sizeUnitsOptions"
                          input-id="size-units"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      @click="onSubmit"
                    >
                      Next
                    </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('color')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="colorDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'color'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('color') }}
                </b-badge>
                Color
              </b-button>
            </b-card-header>
            <b-collapse
              id="color"
              :visible="activeSection === 'color'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <b-form-group
                    label="Color Description"
                    label-for="color"
                  >
                    <b-form-input
                      id="color"
                      v-model="color"
                      trim
                    />
                  </b-form-group>
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      @click="onSubmit"
                    >
                      Next
                    </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('images')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="imagesDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'images'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('images') }}
                </b-badge>
                Images and Artwork
              </b-button>
            </b-card-header>
            <b-collapse
              id="images"
              :visible="activeSection === 'images'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <style-media-grid-form
                    :style-media="styleMedia"
                    :submit-handler="filesUpload"
                    :fetch-style-material-media="fetchStyleEmbellishmentMedia"
                  />
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      @click="openNextSection"
                    >
                      Next
                    </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('sourcing')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="sourcingDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'sourcing'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('sourcing') }}
                </b-badge>
                Sourcing Details
              </b-button>
            </b-card-header>
            <b-collapse
              id="sourcing"
              :visible="activeSection === 'sourcing'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <b-form-group
                    label="Supplier"
                    label-for="supplier"
                  >
                    <b-form-select
                      v-model="supplier"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="filteredSupplierOptions"
                      input-id="supplier"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Price"
                    label-for="price"
                  >
                    <b-form-input
                      id="price"
                      v-model="price"
                      trim
                    />
                  </b-form-group>
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      @click="onSubmit"
                    >
                      Next
                    </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('placement')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="placementDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'placement'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('placement') }}
                </b-badge>
                Placement
              </b-button>
            </b-card-header>
            <b-collapse
              id="placement"
              :visible="activeSection === 'placement'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <b-form-group
                    label="Placement"
                    label-for="placement"
                  >
                    <b-form-textarea
                      id="placement"
                      v-model="placement"
                      trim
                      rows="3"
                      max-rows="5"
                    />
                  </b-form-group>
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      @click="onSubmit"
                    >
                      Next
                    </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          v-if="activeSection === ''"
          class="d-flex align-items-center mb-2"
        >
          <b-button
            variant="primary"
            size="md"
            class="mr-2"
            @click="goBack"
          >
            Save
          </b-button>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardText, BCol,
  BCollapse, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BImg,
  BInputGroup, BInputGroupAppend, BLink, BMedia, BRow,
} from 'bootstrap-vue'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import StyleMediaGridForm from '../material/StyleMediaGridForm.vue'
import styleStoreModule from '../styleStoreModule'

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BMedia,
    BRow,

    StyleMediaGridForm,
  },
  directives: {
    Ripple,
  },
  setup(props, { root }) {
    const toast = useToast()

    const categoryTitles = {
      embroidery: 'Embroidery',
      print: 'Print',
      'heat-transfer': 'Heat Transfer',
      sublimation: 'Sublimation',
      'all-over-print': 'All over Print',
      rhinestones: 'Rhinestones',
      finishings: 'Finishings',
    }

    const qualityOptionsMap = {
      embroidery: ['Flat', 'Chenille', 'Embossing', '3D', 'Patche',
        'Laser Cut', 'Other'],
      print: ['Silk Screen', 'Digital', '3D', 'Flock', 'Gloss', 'Foil',
        'Waterbase', 'Other'],
      'heat-transfer': ['Flat', '3D', 'Other'],
      sublimation: ['Local', 'All over', 'Rapport Size', 'Other'],
      'all-over-print': ['Digital', 'Silkscreen', 'Rapport Size', 'Other'],
      finishings: ['Garment Dye - Conventional', 'Garment Dye - Organic',
        'Garment Dye - Mineral', 'Garment Dye - Reactive',
        'Garment Dye - Pigment', 'Rinse Wash', 'Silicon Wash', 'Stone wash',
        'Anti-Pilling', 'Other'],
    }

    const threadsQualityOptions = [
      'Polyester', 'Viscose', 'Cotton (only ecru)', 'Other',
    ]
    const sizeUnitsOptions = ['mm', 'cm', 'pieces']

    const categoryType = ref(router.currentRoute.params.category)
    const isNew = router.currentRoute.query.new === '1'
    const isAdd = router.currentRoute.name === 'apps-styles-add-embellishment'
    const isEdit = !isAdd

    const dimensionsDisabled = ref(isAdd || isNew || categoryType.value === 'rhinestones')
    const colorDisabled = ref(isAdd || isNew)
    const imagesDisabled = ref(isAdd || isNew)
    const sourcingDisabled = ref(isAdd || isNew)
    const placementDisabled = ref(isAdd || isNew)

    const styleData = ref(null)
    const styleMedia = ref([])

    const quality = ref('')
    const threadsQuality = ref('')
    const size = ref('')
    const sizeUnits = ref('')
    const color = ref('')
    const supplier = ref('')
    const price = ref('')
    const placement = ref('')

    const userRole = JSON.parse(localStorage.getItem('userData')).role

    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const supplierOptions = computed(() => {
      const options = root.$store.getters['app-style/supplierOptions']
      return options
    })
    const categoryOptions = computed(() => {
      const options = root.$store.getters['app-style/categoryOptions']
      return options
    })

    const filteredSupplierOptions = computed(() => supplierOptions.value
      .filter(supp => supp.category_types.includes(categoryType.value)))

    const categoryText = computed(() => category => {
      const found = Array.from(categoryOptions.value)
        .find(option => option.value === category)
      return found ? found.label : category
    })

    const categoryTitle = computed(() => categoryTitles[categoryType.value])

    const headerTitle = computed(() => (
      `${isAdd ? 'New' : 'Edit'} ${categoryTitle.value}`
    ))

    const initialActiveSection = () => {
      if (categoryType.value === 'rhinestones') {
        return isAdd ? 'dimensions' : 'color'
      }
      if (categoryType.value === 'finishings') {
        return isAdd ? 'specifics' : 'color'
      }
      return isAdd ? 'specifics' : 'dimensions'
    }

    const activeSection = ref(initialActiveSection())

    const availableSections = computed(() => {
      let sections = [
        'specifics', 'dimensions', 'color', 'images', 'sourcing', 'placement']

      if (categoryType.value === 'rhinestones') {
        sections = ['dimensions', 'color', 'images', 'sourcing', 'placement']
      } else if (categoryType.value === 'finishings') {
        sections = ['specifics', 'color', 'sourcing']
      }

      if (userRole === 'brand' || userRole === 'brand-manager') {
        sections = sections.filter(item => item !== 'sourcing')
      }
      return sections
    })

    const sectionNumber = computed(() => section => (
      availableSections.value.indexOf(section) + 1))

    const optionsInit = () => {
      root.$store.dispatch('app-style/fetchFormOptions')
    }

    const fetchStyle = () => {
      store.dispatch('app-style/fetchStyle', { id: router.currentRoute.params.id })
        .then(response => {
          styleData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleData.value = undefined
          }
        })
    }

    const fetchEmbellishment = () => {
      const embellishmentId = router.currentRoute.params.embellishment
      if (!embellishmentId) {
        return
      }
      store.dispatch('app-style/fetchStyleEmbellishment', { id: embellishmentId })
        .then(response => {
          const { data } = response
          quality.value = data.quality
          threadsQuality.value = data.threads_quality
          size.value = data.size
          sizeUnits.value = data.size_units
          color.value = data.color
          supplier.value = data.supplier
          price.value = data.price
          placement.value = data.placement

          if (data.category_type) {
            categoryType.value = data.category_type
              .toLowerCase().replace(' ', '-')
            if (isNew) {
              [[, activeSection.value]] = [availableSections.value]
            } else {
              [[activeSection.value]] = [availableSections.value]
            }

            if (activeSection.value === 'color') {
              colorDisabled.value = false
            } else if (activeSection.value === 'dimensions') {
              dimensionsDisabled.value = false
            }
          }
        })
    }

    const fetchStyleEmbellishmentMedia = () => {
      const id = router.currentRoute.params.embellishment
      if (id === undefined) {
        return
      }
      store.dispatch('app-style/fetchStyleEmbellishmentMedia', { id })
        .then(response => {
          styleMedia.value = response.data.style_material_media
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleMedia.value = []
          }
        })
    }

    const goBack = () => {
      router.push({
        name: 'apps-styles-edit',
        params: { id: router.currentRoute.params.id },
        hash: '#3',
      })
    }

    const openNextSection = () => {
      const totalAvailableSections = availableSections.value.length
      const sectionIndex = availableSections.value.indexOf(activeSection.value)
      if (sectionIndex + 1 < totalAvailableSections) {
        activeSection.value = availableSections.value[sectionIndex + 1]
      } else {
        activeSection.value = ''
      }
      if (activeSection.value === 'dimensions') {
        dimensionsDisabled.value = false
      } else if (activeSection.value === 'color') {
        colorDisabled.value = false
      } else if (activeSection.value === 'images') {
        imagesDisabled.value = false
      } else if (activeSection.value === 'sourcing') {
        sourcingDisabled.value = false
      } else if (activeSection.value === 'placement') {
        placementDisabled.value = false
      }
    }

    const embellishmentCreated = response => {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${categoryTitle.value} created`,
          icon: 'InfoIcon',
          variant: 'success',
        },
      })
      router.push({
        name: 'apps-styles-edit-embellishment',
        params: {
          id: router.currentRoute.params.id,
          embellishment: response.data.id,
        },
        query: {
          new: '1',
        },
      })
    }

    const embellishmentUpdated = dontOpenNext => {
      const sectionTitles = {
        specifics: 'Specifics characteristics',
        dimensions: 'Dimensions',
        color: 'Color',
        images: 'Images and Artwork',
        sourcing: 'Sourcing Details',
        placement: 'Placement',
      }

      let title = ''
      if (activeSection.value === 'placement') {
        title = `${categoryTitle.value} Saved`
      } else {
        title = `${sectionTitles[activeSection.value]} Updated`
      }
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'success',
        },
      })
      if (!dontOpenNext) {
        openNextSection()
      }
    }

    const qualityOptions = computed(() => qualityOptionsMap[categoryType.value] || [])

    const onSubmit = () => {
      let data = {
        category_type: categoryTitle.value,
        quality: quality.value,
        threads_quality: threadsQuality.value,
        size: size.value,
        size_units: sizeUnits.value,
        color: color.value,
        supplier: supplier.value,
        price: price.value,
        placement: placement.value,
      }
      if (isEdit) {
        data = { id: router.currentRoute.params.embellishment, ...data }
        store.dispatch('app-style/updateStyleEmbellishment', data)
          .then(() => {
            embellishmentUpdated()
          })
      } else {
        data = { id: router.currentRoute.params.id, ...data }
        store.dispatch('app-style/addStyleEmbellishment', data)
          .then(response => {
            embellishmentCreated(response)
          })
      }
    }

    const filesUpload = files => {
      if (files.value) {
        const styleMediaUpload = new FormData()
        styleMediaUpload.append('tag', categoryTitle.value)
        files.value.forEach(upload => {
          styleMediaUpload.append('files', upload)
        })
        store.dispatch('app-style/addStyleEmbellishmentMedia', {
          id: router.currentRoute.params.embellishment,
          styleMediaUpload,
        })
          .then(() => {
            fetchStyleEmbellishmentMedia()
            embellishmentUpdated(true)
          })
      }
    }

    onMounted(optionsInit)
    onMounted(fetchStyle)
    onMounted(fetchEmbellishment)
    onMounted(fetchStyleEmbellishmentMedia)

    return {
      isNew,
      categoryType,
      activeSection,
      dimensionsDisabled,
      colorDisabled,
      imagesDisabled,
      sourcingDisabled,
      placementDisabled,

      styleData,
      styleMedia,
      userRole,

      quality,
      threadsQuality,
      size,
      sizeUnits,
      color,
      supplier,
      price,
      placement,

      categoryText,
      categoryTitles,
      headerTitle,
      initialActiveSection,
      availableSections,
      sectionNumber,
      qualityOptions,
      threadsQualityOptions,
      sizeUnitsOptions,
      supplierOptions,
      filteredSupplierOptions,

      fetchStyle,
      goBack,
      openNextSection,
      onSubmit,
      filesUpload,
      fetchStyleEmbellishmentMedia,
    }
  },
}
</script>

<style lang="scss">
label {
  font-weight: 600;
}
.card-overflow-visible {
  overflow: visible!important;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
